import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
// import { useDispatch } from "react-redux";
import Modal, { Prompt, ModalFooter } from "@ingka/modal";

import ikeaLogo from "../../../assets/images/ikea-logo.svg";
import iObserveLogo from "../../../assets/images/iObserve.png";
import Button from "@ingka/button";
import Banner from "@ingka/banner";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/button/dist/style.css";
import "@ingka/focus/dist/style.css";
import "@ingka/banner/dist/style.css";
import "@ingka/modal/dist/style.css";

import account from "@ingka/ssr-icon/paths/person";
import questionMark from "@ingka/ssr-icon/paths/question-mark";
import bellIcon from "@ingka/ssr-icon/paths/bell";
import linkOutIcon from "@ingka/ssr-icon/paths/link-out";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import styles from "./Navbar.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getBanner } from "../../../redux/shiftHandover/actions";

const Navbar = ({ helpIcon, styleClass = "" }) => {
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const { bannerMessage} = useSelector(
    (state) => state.shiftHandover
  )
  const {token} = useSelector(
    (state) => state.at
  )

  const { selectedSupportArea } = useSelector(
    (state) => state.shiftHandover
);


  // const [showSurveyModal, setShowSurveyModal] = useState(false);
  // const dispatch = useDispatch();

  const history = useHistory();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(()=> {
    if(token)
    dispatch(getBanner(selectedSupportArea))
  },[token])

  return (
    <>
      {process.env.REACT_APP_ENV === "DEV" && (
        <Banner
          body={
            <p>
              This is a staging environment, data here might not be up to date.
              Please{" "}
              <a
                className={styles.url}
                href={`https://iobserve.oi.ingka.com${history.location.pathname}`}
              >
                click here
              </a>{" "}
              for the production instance.{" "}
            </p>
          }
          emergency
          dismissable
        />
      )}
       {history.location.pathname.includes("/shift-handover") && bannerMessage?.bannertext && (
        <Banner
          body={
            bannerMessage?.bannertext?.split("\n").map((text, id)=>
            <p key={id}>
              {text}
            </p>
            )
          }
          emergency
          dismissable
        />
      )}

      {history.location.pathname === "/self-service" &&
        process.env.REACT_APP_ENV === "PROD" && (
          <Banner
            body={
              <p>
                For Service mapping related actions, please visit{" "}
                <a
                  className={styles.url}
                  href={`https://iobserve.oi.ingka.com/osvisualization/servicemapping`}
                >
                  this page
                </a>
              </p>
            }
            emergency
            dismissable
          />
        )}
      <div className={`${styles.container} ${styleClass}`}>
        {/* <img src={menuIcon} alt='menu icon' /> */}
        <img src={ikeaLogo} alt="IKEA logo" className={styles.logo} />
        <img
          src={iObserveLogo}
          alt="iObserve logo"
          className={styles.logoiO}
          onClick={() => {
            history.push("/");
          }}
        />
        {/* <img src={userIcon} alt='user icon' className={styles.userIcon} /> */}

        {!isAuthenticated && (
          <Button
            style={{ marginLeft: "auto" }}
            onClick={() => loginWithRedirect({})}
            className="btn--primary"
            type="button"
            inverseTheme
            small
          >
            Login
          </Button>
        )}
        {isAuthenticated && (
          <div className={styles.user}>
            {/* <h4 style={{ margin: 0 }}>{user.email}</h4> */}
            {/* <p>{user.email}</p> */}
            {/* <button
              className={styles.btnPrimary}
              style={{ marginLeft: "20px" }}
              onClick={() => logout({ returnTo: window.location.origin })}
              type='button'
            >
              Log out
            </button> */}
            {helpIcon === true && (
              <Button
                type="primary"
                small
                onClick={() => {
                  history.push("/self-service");
                }}
                text="Support centre"
              />
            )}
            {/* <div className={styles.notificationBtnWrapper}>
              <Button
                type="primary"
                small
                iconOnly
                ssrIcon={bellIcon}
                onClick={() => setShowSurveyModal(true)}
                style={{ marginLeft: "20px" }}
              />
              {localStorage.getItem("isSurveyCompleted") !== "true" && (
                <div className={styles.notificationBtnBadge}>1</div>
              )}
            </div> */}
            <Button
              type="primary"
              small
              iconOnly
              ssrIcon={account}
              onClick={handleClick}
              style={{ marginLeft: "20px" }}
            />
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              style={{ marginTop: "30px" }}
            >
              {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>My account</MenuItem> */}
              <MenuItem
                onClick={() => {
                  // dispatch({type: 'LOGOUT'});
                  logout({ returnTo: window.location.origin });
                }}
              >
                Logout
              </MenuItem>
            </Menu>
          </div>
        )}
      </div>
      {/* <Modal visible={showSurveyModal}>
        <Prompt
          title={`Hej ${user?.given_name},`}
          footer={
            <ModalFooter compact={true}>
              <Button
                text="Go to survey"
                type="primary"
                onClick={() => {
                  window.open(
                    "https://forms.office.com/Pages/ResponsePage.aspx?id=emMLclplz0CBavIvQHVcLDlrXlliriVOvAcI3yYDIytUMU5aMlE5VlRTQTdZWEJJOUZXTExJQjMzTi4u",
                    "_blank"
                  );
                  localStorage.setItem("isSurveyCompleted", "true");
                  setShowSurveyModal(false);
                }}
                ssrIcon={linkOutIcon}
                iconPosition="trailing"
              />
              <Button
                text="I'll do later"
                type="secondary"
                onClick={() => {
                  // localStorage.setItem("isSurveyCompleted", "true");
                  setShowSurveyModal(false);
                }}
              />
            </ModalFooter>
          }
        >
          <p>
            There is a survey ongoing. We would love to have your feedback on
            iObserve.{" "}
          </p>
        </Prompt>
      </Modal> */}
    </>
  );
};

Navbar.defaultProps = {
  helpIcon: true,
};

export default Navbar;