import {
  GET_SEARCH_RESULTS,
  GET_REPO_OVERVIEW,
  SET_FETCHING_FLAG,
  GET_COMMIT_FREQUENCY,
  GET_TOP_CONTRIBUTORS,
  GET_ORG_DATA,
  GET_BRANCH_OVERVIEW,
  GET_PR_OVERVIEW,
  GET_ORG_ACTIVITIES,
  GET_ORG_ACTIVITIES_LIST,
  GET_ORG_TOP_CONTRIBUTORS,
  GET_DEPLOYMENT_STATS,
  GET_DEPENDENCIES,
  SET_TOKEN,
  SET_DATA_CONTROL,
  TOGGLE_FETCH_DATA
} from "./types";

import {store} from '../store.js';

import axios from "axios";

const url = "https://gitviz-dot-ikea-itsd-ml.appspot.com/aiops/iobserve/github";
const orgGitURL = `https://git-org-visualization-dot-ikea-itsd-ml.appspot.com/aiops/iobserve/gitviz`;
const gitBaseURL = `https://git-visualization-dot-ikea-itsd-ml.appspot.com/aiops/iobserve/gitviz`;
const orgGitDepartmentUrl = "https://gitvizorgservice-soyaaeguoa-ez.a.run.app/aiops/iobserve/gitviz"

const getSearchResults = (searchstring, gittype) => (dispatch) => {
  dispatch({
    type: SET_FETCHING_FLAG,
    payload: {
      apiType: "getSearchResults",
      value: true,
    },
  });
  axios
    .post(`${url}/searchrepos`, {
      Response: {
        searchstring: searchstring,
        gittype: gittype,
      },
    })
    .then((response) => {
      dispatch({
        type: GET_SEARCH_RESULTS,
        payload: response.data.data.search.nodes,
      });
      dispatch({
        type: SET_FETCHING_FLAG,
        payload: {
          apiType: "getSearchResults",
          value: false,
        },
      });
    })
    .catch((error) => {
      console.error(error);
      dispatch({
        type: SET_FETCHING_FLAG,
        payload: {
          apiType: "getSearchResults",
          value: false,
        },
      });
    });
};

const getRepoList = (gittype) => (dispatch) => {
  dispatch({
    type: SET_FETCHING_FLAG,
    payload: {
      apiType: "getRepoList",
      value: true,
    },
  });

  const headerData = getHeaders();

  axios
    .get(`${gitBaseURL}/repo/getrepositories?gittype=${gittype}`, {
      headers: headerData
    } )
    .then((response) => {
      let repoList = [];
      response?.data?.map(itr => {
        let temp = itr.repositories?.map( repo => {
          return {organisationname: itr.organisationname, repo: repo}
        } ); 
        repoList = [...repoList, ...temp];
      } );
      dispatch({
        type: GET_SEARCH_RESULTS,
        // payload: response.data.Response && response.data.Response.length ? response.data.Response : [],
        // payload: response.data.response && response.data.response.length ? response.data.response.sort() : [],
        payload: repoList,
      });
      dispatch({
        type: SET_FETCHING_FLAG,
        payload: {
          apiType: "getRepoList",
          value: false,
        },
      });
    })
    .catch((error) => {
      console.error(error);
      dispatch({
        type: SET_FETCHING_FLAG,
        payload: {
          apiType: "getRepoList",
          value: false,
        },
      });
    });
};

const getRepoOverview = (ownername, reponame, gittype) => (dispatch) => {
  dispatch({
    type: SET_FETCHING_FLAG,
    payload: {
      apiType: "getRepoOverview",
      value: true,
    },
  });

  const headerData = getHeaders();

  axios
    .post(`${gitBaseURL}/repo/repositoryinfo`, {
      payload: {
        organisationname: ownername,
        repositoryname: reponame,
        gittype: gittype,
      },
    }, {headers: headerData} )
    .then((response) => {
      dispatch({
        type: GET_REPO_OVERVIEW,
        // payload: response.data.data.repository,
        payload: response.data.response,
      });
      dispatch({
        type: SET_FETCHING_FLAG,
        payload: {
          apiType: "getRepoOverview",
          value: false,
        },
      });
    })
    .catch((error) => {
      console.error(error);
      dispatch({
        type: SET_FETCHING_FLAG,
        payload: {
          apiType: "getRepoOverview",
          value: false,
        },
      });
    });
};

const getCommitFrequency =
  (ownername, reponame, gittype, period) => (dispatch) => {
    dispatch({
      type: SET_FETCHING_FLAG,
      payload: {
        apiType: "getCommitFrequency",
        value: true,
      },
    });

    const headerData = getHeaders();

    axios
      .post(`${gitBaseURL}/repo/commitoverview`, {
        payload: {
          organisationname: ownername,
          repositoryname: reponame,
          gittype: gittype,
          duration: period,
        },
      }, {headers: headerData})
      .then((response) => {
        const modifiedArr = [
          {
            id: "activity",
            color: "hsl(353, 70%, 50%)",
            data: response.data.response.map((el) => ({
              x: el.date,
              y: el.commitcount,
            })),
          },
        ];
        dispatch({
          type: GET_COMMIT_FREQUENCY,
          payload: modifiedArr,
        });
        dispatch({
          type: SET_FETCHING_FLAG,
          payload: {
            apiType: "getCommitFrequency",
            value: false,
          },
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch({
          type: SET_FETCHING_FLAG,
          payload: {
            apiType: "getCommitFrequency",
            value: false,
          },
        });
      });
  };

const getTopContributors =
  (ownername, reponame, gittype, period) => (dispatch) => {
    dispatch({
      type: SET_FETCHING_FLAG,
      payload: {
        apiType: "getTopContributors",
        value: true,
      },
    });

    const headerData = getHeaders();

    axios
      .post(`${gitBaseURL}/repo/activitycontributors`, {
        payload: {
          organisationname: ownername,
          repositoryname: reponame,
          gittype: gittype,
          duration: period,
        },
      }, {headers: headerData})
      .then((response) => {
        dispatch({
          type: GET_TOP_CONTRIBUTORS,
          payload: response.data.response,
        });
        dispatch({
          type: SET_FETCHING_FLAG,
          payload: {
            apiType: "getTopContributors",
            value: false,
          },
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch({
          type: SET_FETCHING_FLAG,
          payload: {
            apiType: "getTopContributors",
            value: false,
          },
        });
      });
  };

const getBranchOverview = (ownername, reponame, gittype) => (dispatch) => {
  dispatch({
    type: SET_FETCHING_FLAG,
    payload: {
      apiType: "getBranchOverview",
      value: true,
    },
  });

  const headerData = getHeaders();

  axios
    .post(
      `${gitBaseURL}/repo/branchstatus`,
      {
        payload: {
          gittype: gittype,
          repositoryname: reponame,
          organisationname: ownername,
        },
      },
      { headers: headerData }
    )
    .then((response) => {
      const modifiedRes = {
        chartData: [
          { id: "Stale Branches", value: response.data.reponse.stalebranch },
          { id: "Active Branches", value: response.data.reponse.activebranch },
        ],
        activeBranchesCount: response.data.reponse.activebranch,
        staleBranchesCount: response.data.reponse.stalebranch,
        defaultBranch: response.data.reponse.defaultbranchname,
        lastUpdatedAt: response.data.reponse.defaultbranchupdatedat,
      };
      // console.log(`modifiedRes : ${JSON.stringify(modifiedRes)}`);
      dispatch({
        type: GET_BRANCH_OVERVIEW,
        payload: modifiedRes,
      });
      dispatch({
        type: SET_FETCHING_FLAG,
        payload: {
          apiType: "getBranchOverview",
          value: false,
        },
      });
    })
    .catch((error) => {
      console.error(error);
      dispatch({
        type: GET_BRANCH_OVERVIEW,
        payload: {
          chartData: [],
          activeBranchesCount: 0,
          staleBranchesCount: 0,
          defaultBranch: '',
          lastUpdatedAt: '',
        },
      });
      dispatch({
        type: SET_FETCHING_FLAG,
        payload: {
          apiType: "getBranchOverview",
          value: false,
        },
      });
    });
};

const getPROverview = (ownername, reponame, gittype, period) => (dispatch) => {
  dispatch({
    type: SET_FETCHING_FLAG,
    payload: {
      apiType: "getPROverview",
      value: true,
    },
  });

  const headerData = getHeaders();

  axios
    .post(
      `${gitBaseURL}/repo/pullrequest`,
      {
        payload: {
          organisationname: ownername,
          repositoryname: reponame,
          gittype: gittype,
          duration: period,
        },
      },
      {headers: headerData}
    )
    .then((response) => {
      const modifiedRes = {
        chartData2: [
          {
            id: "Average Review Time",
            color: "hsl(353, 70%, 50%)",
            data: response.data.response["accept/reject"]?.merged?.map((el) => ({
              x: el.date,
              y: el.averagemergetime,
            })),
          },
        ],
        chartData: [
          {
            id: "Closed",
            color: "hsl(353, 70%, 50%)",
            data: response.data.response["accept/reject"].closed.map((el) => ({
              x: el.date,
              y: el.count,
            })),
          },
          {
            id: "Merged",
            color: "hsl(353, 70%, 50%)",
            data: response.data.response["accept/reject"].merged.map((el) => ({
              x: el.date,
              y: el.count,
            })),
          },
          {
            id: "Total",
            color: "hsl(353, 70%, 50%)",
            data: response.data.response["accept/reject"].merged.map((el) => ({
              x: el.date,
              y: el.count,
            })),
          },
        ],
      };
      dispatch({
        type: GET_PR_OVERVIEW,
        payload: modifiedRes,
      });
      dispatch({
        type: SET_FETCHING_FLAG,
        payload: {
          apiType: "getPROverview",
          value: false,
        },
      });
    })
    .catch((error) => {
      console.error(error);
      dispatch({
        type: SET_FETCHING_FLAG,
        payload: {
          apiType: "getPROverview",
          value: false,
        },
      });
    });
};

const getOrgData = (userId, token, isInitialFetch = false) => (dispatch) => {
  try {
    dispatch({
      type: SET_FETCHING_FLAG,
      payload: {
        apiType: "getOrgData",
        value: true,
      },
    });
    const request = {
      payload: {
        // user: userId
        username: userId,
      },
    };
    const data = axios
      .post(`${orgGitDepartmentUrl}/org/departmentstructure`, request, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const fomattedOrganizationData = formatOrgDataV2(response?.data?.response);
        const organizationData = fomattedOrganizationData.orgData;
        const selectedDept = fomattedOrganizationData.depData;
        const depList = fomattedOrganizationData.depList;

        dispatch({
          type: GET_ORG_DATA,
          payload: {
            orgData:
              organizationData && organizationData.children[0]
                ? organizationData.children[0]
                : {},
            selectedDept: selectedDept,
            depList: depList
          },
        });
        if(isInitialFetch){
          dispatch({
            type: 'SET_DEPT_LIST',
            payload: depList
          });
          dispatch({
            type: 'TOGGLE_FETCH_DATA'
          });
        }
        dispatch({
          type: SET_FETCHING_FLAG,
          payload: {
            apiType: "getOrgData",
            value: false,
          },
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch({
          type: SET_FETCHING_FLAG,
          payload: {
            apiType: "getOrgData",
            value: false,
          },
        });
      });
  } catch (error) {
    dispatch({
      type: SET_FETCHING_FLAG,
      payload: {
        apiType: "getOrgData",
        value: false,
      },
    });
  }
};

const getOrgActivityData = (department, period, token) => (dispatch) => {
  try {
    dispatch({
      type: SET_FETCHING_FLAG,
      payload: {
        apiType: "getOrgActivityData",
        value: true,
      },
    });

    let iscontrolled = store?.getState()?.gitvisualization?.isControlled;

    const request = {
      payload: {
        departmentlist: department,
        duration: period,
        gittype: "github",
        iscontrolled: iscontrolled
      },
    };

    axios
      .post(`${orgGitURL}/org/recentcommits`, request, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const activityData = response?.data?.response;

        const dateGroup = groupBy(activityData, data => {
          if(data['commitdate'] && data['commitdate'] !== '' ){
            return data['commitdate'].split('T')[0];
          }
          else{
            return 'others';
          }
        } );

        const tempDataList = [];
        try {
          Object.keys(dateGroup).sort().map(key => {
            tempDataList.push({
              date: key,
              data: dateGroup[key]
            });
          });
        } catch (error) {

        }        

        dispatch({
          type: GET_ORG_ACTIVITIES,
          // payload: activityData.length ? activityData.reverse() : [],
          payload: {
            activityData: activityData.length ? activityData.reverse() : [],
            groupedActivityData: tempDataList.length ? tempDataList : [],
          },
        });
        dispatch({
          type: SET_FETCHING_FLAG,
          payload: {
            apiType: "getOrgActivityData",
            value: false,
          },
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch({
          type: SET_FETCHING_FLAG,
          payload: {
            apiType: "getOrgActivityData",
            value: false,
          },
        });
      });
  } catch (error) {
    dispatch({
      type: SET_FETCHING_FLAG,
      payload: {
        apiType: "getOrgActivityData",
        value: false,
      },
    });
  }
};

const getOrgActivities = (department, period, token) => (dispatch) => {
  try {
    dispatch({
      type: SET_FETCHING_FLAG,
      payload: {
        apiType: "getOrgActivities",
        value: true,
      },
    });

    let iscontrolled = store?.getState()?.gitvisualization?.isControlled;

    const request = {
      payload: {
        departmentlist: department,
        duration: period,
        gittype: "github",
        iscontrolled: iscontrolled
      },
    };

    axios
      .post(`${orgGitURL}/org/activities`, request, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const activityData = response?.data?.response;
        const payload = [];
        Object.keys(activityData).map((key) => {
          payload.push({
            value: activityData[key].length,
            day: key,
            activities: activityData[key]
          });
        });
        dispatch({
          type: GET_ORG_ACTIVITIES_LIST,
          payload: payload,
        });
        dispatch({
          type: SET_FETCHING_FLAG,
          payload: {
            apiType: "getOrgActivities",
            value: false,
          },
        });
      })
      .catch((error) => {
        console.error(`Error in getOrgActivities :: ${error}`);
        dispatch({
          type: SET_FETCHING_FLAG,
          payload: {
            apiType: "getOrgActivities",
            value: false,
          },
        });
      });
  } catch (error) {
    console.error(`Error in getOrgActivities :: ${error}`);
    dispatch({
      type: SET_FETCHING_FLAG,
      payload: {
        apiType: "getOrgActivities",
        value: false,
      },
    });
  }
};

const getOrgTopContributors = (department, period, token) => (dispatch) => {
  try {
    dispatch({
      type: SET_FETCHING_FLAG,
      payload: {
        apiType: "getOrgTopContributors",
        value: true,
      },
    });

    let iscontrolled = store?.getState()?.gitvisualization?.isControlled;

    const request = {
      payload: {
        departmentlist: department,
        duration: period,
        gittype: "github",
        iscontrolled: iscontrolled
      },
    };

    axios
      .post(`${orgGitURL}/org/topcontributors`, request, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const activityData = response?.data?.response;
        // const payload = [
        //   { count: 5, user: "aswinkal1" },
        //   { count: 3, user: "pravs1" },
        //   { count: 4, user: "arper" },
        //   { count: 7, user: "gowtt" },
        //   { count: 10, user: "hasan" },
        // ].sort((a, b) => a.count - b.count );

        const payload = activityData.sort((a, b) => a.count - b.count)

      // const payload = activityData.sort((a, b) => a.count - b.count).map(itr => {
      //   let userInfo = {...itr, userInfo: `${itr['user']}~${itr['avatarurl']?.[0] ? itr['avatarurl']?.[0] : ''}`}
      //   return userInfo
      //  });

        dispatch({
          type: GET_ORG_TOP_CONTRIBUTORS,
          payload: activityData,
        });
        dispatch({
          type: SET_FETCHING_FLAG,
          payload: {
            apiType: "getOrgTopContributors",
            value: false,
          },
        });
      })
      .catch((error) => {
        console.error(`Error in getOrgTopContributors :: ${error}`);
        dispatch({
          type: SET_FETCHING_FLAG,
          payload: {
            apiType: "getOrgTopContributors",
            value: false,
          },
        });
      });
  } catch (error) {
    console.error(`Error in getOrgTopContributors :: ${error}`);
    dispatch({
      type: SET_FETCHING_FLAG,
      payload: {
        apiType: "getOrgTopContributors",
        value: false,
      },
    });
  }
};

const setSelectedDept = (payload) => (dispatch) => {
  try {
    dispatch({
      type: "SET_SELECTED_DEPT",
      payload: payload,
    });
  } catch (error) {}
};

const formatOrgData = (organizationData) => {
  // let newObj = current = {};
  let current = {};
  let newObj = current;
  let managers = organizationData?.managers;
  // let managers = organizationData?.Managers;
  let reporties = organizationData?.reporties;
  // restrict non-IKEA employees dept.
  let selectedDept = { ...organizationData };
  if (
    selectedDept &&
    selectedDept.department &&
    (selectedDept.department === "" || selectedDept.department === "NA")
  ) {
    selectedDept["department"] = managers?.[managers.length - 1]?.department;
  }
  for (let i = 0; i <= managers.length; i++) {
    if (i < managers.length) {
      current["children"] = [{ ...managers[i] }];
      current = current["children"][0];
    }
    if (i === managers.length) {
      let reporteesData = [];
      reporties.map((itr) => {
        if (itr !== organizationData?.department) {
          reporteesData.push({ department: itr });
        }
      });
      const managerDept = managers[i - 1]["department"];
      if (
        organizationData.department &&
        organizationData.department !== "NA" &&
        organizationData.department !== "" &&
        managerDept !== selectedDept["department"]
      ) {
        current["children"] = [
          {
            department: organizationData.department,
            networkid: organizationData.networkid,
            userid: organizationData.user,
            children: reporteesData,
          },
        ];
        current = current["children"][0];
      }
    }
  }
  const orgData = {
    orgData: newObj,
    depData: selectedDept,
  };
  // return newObj;
  return orgData;
};

const getDeploymentStats = (ownername, reponame, gittype, period) => (dispatch) => {
  dispatch({
    type: SET_FETCHING_FLAG,
    payload: {
      apiType: "getDeploymentStats",
      value: true,
    },
  });

  const headerData = getHeaders();

  axios
    .post(
      `${gitBaseURL}/repo/deploymentrate`,
      {
        payload: {
          organisationname: ownername,
          repositoryname: reponame,
          gittype: gittype,
          duration: period,
        },
      },
      {headers: headerData}
    )
    .then((response) => {
      // const modifiedRes = {
      //   chartData: [
      //     // {
      //     //   id: "Success",
      //     //   color: "hsl(353, 70%, 50%)",
      //     //   data: response.data.response.map((el) => ({
      //     //     x: el.date,
      //     //     y: el.successcount,
      //     //   })),
      //     // },
      //     {
      //       id: "Failure",
      //       color: "hsl(353, 70%, 50%)",
      //       data: response.data.response.map((el) => ({
      //         x: el.date,
      //         y: el.failurecount,
      //       })),
      //     },
      //   ],
      // };

      const chartData = [];
      if(response.data.response && response.data.response.length){
        chartData.push({
          id: "Failure",
          color: "hsl(353, 70%, 50%)",
          data: response.data.response.map((el) => ({
            x: el.date,
            y: el.failurecount,
          })),
        });
      }
      
      dispatch({
        type: GET_DEPLOYMENT_STATS,
        payload: chartData,
      });
      dispatch({
        type: SET_FETCHING_FLAG,
        payload: {
          apiType: "getDeploymentStats",
          value: false,
        },
      });
    })
    .catch((error) => {
      console.error(error);
      dispatch({
        type: SET_FETCHING_FLAG,
        payload: {
          apiType: "getDeploymentStats",
          value: false,
        },
      });
    });
};

const formatOrgDataV2 = (organizationData) => {
  // let newObj = current = {};
  let current = {};
  let newObj = current;
  let managers = [...organizationData?.managers];
  // let managers = organizationData?.Managers;
  let reporties = [...organizationData?.reporties];
  let depList = [...organizationData?.recursivedepartments];

  // restrict non-IKEA employees dept.
  let selectedDept = { ...organizationData };
  if (
    selectedDept &&
    selectedDept.department &&
    (selectedDept.department === "" || selectedDept.department === "NA")
  ) {
    selectedDept["department"] = managers?.[managers.length - 1]?.department;
    depList.push(managers?.[managers.length - 1]?.department);
  }

  for (let i = 0; i <= managers.length; i++) {
    if (i < managers.length) {
      current["children"] = [{ ...managers[i] }];
      current = current["children"][0];
    }

    else if(i === managers.length){
      let reporteesData = [];
      const managerDept = managers[i - 1]["department"];

      reporties.map(itr => {
        if(itr.department !== organizationData?.department){
          reporteesData.push(itr);  
        }        
      });

      if (reporties.length === 0 && organizationData?.recursivedepartments?.length > 0) {
        reporteesData.push({department: selectedDept.department,
        mail: organizationData.mail,
        userid: organizationData.user });
        current["children"] = [
          {
            department: organizationData.department,
            networkid: organizationData.networkid,
            userid: organizationData.user,
          },
        ];
        current = current["children"][0];
      }

      // to remove duplicate reportees
      else if ( 
        organizationData.department &&
        organizationData.department !== "NA" &&
        organizationData.department !== "" &&
        managerDept !== selectedDept["department"] && 
        reporteesData.length > 0 
      ) {
        current["children"] = [
          {
            department: organizationData.department,
            networkid: organizationData.networkid,
            userid: organizationData.user,
            children: reporteesData,
          },
        ];
        current = current["children"][0];
      }
    }
  }

  const orgData = {
    orgData: newObj,
    depData: selectedDept,
    depList: depList
  };
  
  return orgData;
};

const getDependencies = (ownername, reponame, gittype) => (dispatch) => {
  dispatch({
    type: SET_FETCHING_FLAG,
    payload: {
      apiType: "getDependencies",
      value: true,
    },
  });

  const headerData = getHeaders();

  axios
    .post(
      `${gitBaseURL}/repo/packagedependencies`,
      {
        payload: {
          organisationname: ownername,
          repositoryname: reponame,
          gittype: gittype,
        },
      },
      {headers: headerData}
    )
    .then((response) => {
      const res = response.data.response.slice(0, 10);
      dispatch({
        type: GET_DEPENDENCIES,
        payload: res,
      });
      dispatch({
        type: SET_FETCHING_FLAG,
        payload: {
          apiType: "getDependencies",
          value: false,
        },
      });
    }).catch(error => {
      console.error(`Error while getting packagedependencies :: ${error}`);
      dispatch({
        type: SET_FETCHING_FLAG,
        payload: {
          apiType: "getDependencies",
          value: false,
        },
      });
    });
};

const groupBy = (list, keyGetter) => {
  const map = {};
  list.forEach((item, idx) => {
    const key = keyGetter(item);
    const collection = map[key];
    if (!collection) {
      map[key] = [item];
    } else {
      collection.push(item);
    }
  });
  return map;
};

const getToken = (auth0Token) => async (dispatch) => {
  try {
    // console.log(`inside getToken`);
    const resData = await (await axios.get(`https://iobserve-backend-soyaaeguoa-ez.a.run.app/aiops/apim/token?env=prod`, {
      headers: {
          Authorization: `Bearer ${auth0Token}`
      }
  })).data;
    // console.log(`resData : ${resData}`)
    if(resData?.access_token){
      dispatch({
        type: SET_TOKEN,
        payload: resData?.access_token
      });
    }
    else{
      dispatch({
        type: SET_TOKEN,
        payload: ''
      });
    }

  } catch (error) {
    console.log(`Error in getting token for git viz. :: ${error}`)
  }
}

const getHeaders = (dispatch) => {
  try {
    let token = store?.getState()?.gitvisualization?.token;
    return {
      Authorization: `Bearer ${token}`
    }

    // if(token && token !== ''){
    //   token = store?.getState()?.gitvisualization?.token;
    //   return token;
    // }
    // else{
    //   await dispatch(getToken);
    //   token = store?.getState()?.gitvisualization?.token;
    //   return token;
    // }    
  } catch (error) {
    console.error(`error in getting header data :: ${error}`);
  }
}

export {
  getSearchResults,
  getRepoList,
  getRepoOverview,
  getCommitFrequency,
  getTopContributors,
  getBranchOverview,
  getPROverview,
  getOrgData,
  getOrgActivityData,
  setSelectedDept,
  getOrgActivities,
  getOrgTopContributors,
  getDeploymentStats,
  getDependencies,
  getToken
};
