import {
    AUTHORIZATION_FAILED,
    AUTHORIZATION_SUCCESS,
    AUTHORIZATION_REQUEST,
    SAVE_CDS_ROLE,
    SET_LOADING,
    SAVE_SPOOF_CDS_ROLE,
    GET_AUTH0_TOKEN,
    GET_APIM_TOKEN
} from './types';

import axios from 'axios';
import { store } from "../store";

const authorizationRequest = () => {
    return {
        type: AUTHORIZATION_REQUEST
    }
}

const authorizationSuccess = (payload) => {
    return {
        type: AUTHORIZATION_SUCCESS,
        payload: payload
    }
}

const authorizationFailed = (errorMessage) => {
    return {
        type: AUTHORIZATION_FAILED,
        payload: errorMessage
    }
}

const getCDSRole = (user) => async (dispatch) => {

    return {

    }
}

const getCDSToken = (user, auth0Token) => async (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    axios.get(`https://iobserve-backend-soyaaeguoa-ez.a.run.app/aiops/iobserve/occ/token`, {
        headers: {
            Authorization: `Bearer ${auth0Token}`
        }
    })
    .then(authResponse => {
        if (authResponse.data.access_token) {
            let token = authResponse.data.access_token;
            // store user details and token in local storage to keep user logged in between page refreshes
            sessionStorage.setItem('cdsToken', token);
            dispatch(getCDSUserDetails(user, token, auth0Token));
        }
        else{
            console.error(`CDS token not available`);
            dispatch({ type: SET_LOADING, payload: false });
        }
    })
    .catch(err => {
        console.error(`error in cds get token :: ${err}`)
        dispatch({ type: SET_LOADING, payload: false });
    });
}

const getCDSUserDetails = (user, token, auth0Token) => async (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    const config = {
        method: "get",
        url: `https://iobserve-backend-soyaaeguoa-ez.a.run.app/aiops/iobserve/occ/user?userId=${user.email}`,
        headers: {
            'Content-Type': 'application/json',
            'token': `${token}`,
            Authorization: `Bearer ${auth0Token}`
        },
    };
    axios(config)
        .then(userResponse => {
            const cdsData = userResponse.data;
            dispatch({ type: SAVE_CDS_ROLE, payload: {cdsData, user} });
            dispatch({ type: SET_LOADING, payload: false });
        })
        .catch(err => {
            console.error(`error in cds get user :: ${err}`)
            dispatch({ type: SET_LOADING, payload: false });
    })
}

const getSpoofCDSUserDetails = (userID) => async (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    const token = sessionStorage.getItem('cdsToken');
    const auth0Token = store.getState().at.auth0token;
    const config = {
        method: "get",
        url: `https://iobserve-backend-soyaaeguoa-ez.a.run.app/aiops/iobserve/occ/user?userId=${userID}`,
        headers: {
            'Content-Type': 'application/json',
            'token': `${token}`,
            Authorization: `Bearer ${auth0Token}`
        },
    };
    axios(config)
        .then(userResponse => {
            const cdsData = userResponse.data;
            dispatch({ type: SAVE_SPOOF_CDS_ROLE, payload: {cdsData} });
            dispatch({ type: SET_LOADING, payload: false });
        })
        .catch(err => {
            console.error(`error in cds get user :: ${err}`)
            dispatch({ type: SET_LOADING, payload: false });
    })
}

const getAuth0Token = (token) =>  (dispatch) => {
    try {
        dispatch({ type: GET_AUTH0_TOKEN, payload: token});
    } catch (error) {
        console.error(error);
    }
}

const getAPIMToken = (token) => async (dispatch) => {
  try {
    const ENV = process.env.REACT_APP_ENV === "PROD" ? "prod" : "ppe";
    const { data } = await axios.get(
      `https://iobserve-backend-soyaaeguoa-ez.a.run.app/aiops/apim/token?env=${ENV}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({
      type: GET_APIM_TOKEN,
      payload: data?.access_token,
    });
  } catch (error) {
    console.error(`Error while retrieving APIM token :: ${error}`);
    console.log(error.toJSON());
  }
};

export { authorizationRequest, authorizationSuccess, authorizationFailed, getCDSRole, getCDSToken, getCDSUserDetails, getSpoofCDSUserDetails, getAuth0Token, getAPIMToken }