import axios from "axios";
import {
  GET_SH_UNASSIGNED_TICKETS,
  GET_SH_CURRENT_HANDOVER,
  SET_SH_FETCHING_FLAG,
  GET_SH_OPERATION_LEADS,
  GET_SH_INCIDENT_DETAILS,
  SET_SH_SELECTED_SUPPORT_AREA,
  GET_SH_ONGOING_PM,
  GET_SH_INCIDENT_COUNTS,
  GET_SH_INCIDENT_COMMENTS_HISTORY,
  GET_SH_INCIDENT_WORKLOG_HISTORY,
  GET_SH_ADMIN_USERS_LIST,
  SET_SH_TOAST_VISIBLE,
  GET_SH_AGENT_ROLES_LIST,
  GET_SH_CDS_USER_DATA,
  GET_SH_BANNER_MESSSAGE,
} from "./types";
import { store } from "../store";

let BASE_API_URL = "";
let BASE_URL_CSH = "";
if (
  process.env.REACT_APP_ENV === "DEV" &&
  process.env.REACT_APP_ITSD_ENV === "DEV"
) {
  BASE_API_URL = "https://dev.api.iobserve.ingka.com";
} else if (
  process.env.REACT_APP_ENV === "DEV" &&
  process.env.REACT_APP_ITSD_ENV === "PPE"
) {
  BASE_API_URL = "https://stage.api.iobserve.ingka.com";
} else if (
  process.env.REACT_APP_ENV === "PROD" &&
  process.env.REACT_APP_ITSD_ENV === "PROD"
) {
  BASE_API_URL = "https://prod.api.iobserve.ingka.com";
}
const BASE_URL_PM =
  "https://ipm-backend.ingka.com";

  if (
    process.env.REACT_APP_ENV === "DEV" &&
    process.env.REACT_APP_ITSD_ENV === "PPE"
  ){

    BASE_URL_CSH = `https://stage.api.iobserve.ingka.com/api/v1/csh/itsd/post_notifications`;
  }else{
    BASE_URL_CSH = `${BASE_API_URL}/api/v1/csh/itsd/post_notifications`
  }

const BASE_URL_SH = `${BASE_API_URL}/aiops/iobserve/shifthandover`;
  

const supportAreaMapping = {
  "Support Team Supply": "ILO & FF", 
  "Support Team Customer":"Customer", 
  "Support Team CTI": "CTI", 
  "Support Team PBO": "PBO"
}

export const getUnattendedHandoversList = async (selectedSupportArea) => {
  try {
    const token = store.getState().at.APIMtoken;
    const response = await axios.post(
      `${BASE_URL_SH}/getunassignedlist`,
      {
        Payload: {
          env: process.env.REACT_APP_ENV,
          supportgroup: selectedSupportArea,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = response.data?.response || [];
    return data;
  } catch (error) {
    console.error(error);
    return [];
  }
};
export const getCurrentHandoversList = async (selectedSupportArea) => {
  try {
    const token = store.getState().at.APIMtoken;
    const response = await axios.post(
      `${BASE_URL_SH}/currenthandoverlist`,
      {
        Payload: {
          supportgroup: selectedSupportArea,
          env: process.env.REACT_APP_ENV,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = response.data?.response || [];
    return data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getCurrentHandovers =
  (selectedSupportArea) => async (dispatch) => {
    try {
      dispatch({
        type: SET_SH_FETCHING_FLAG,
        payload: {
          apiType: "getCurrentHandovers",
          value: true,
        },
      });

      const token = store.getState().at.APIMtoken;
      const response = await axios.post(
        `${BASE_URL_SH}/currenthandoverlist`,
        {
          Payload: {
            supportgroup: selectedSupportArea,
            env: process.env.REACT_APP_ENV,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data?.response || [];
      dispatch({
        type: GET_SH_CURRENT_HANDOVER,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch({
        type: SET_SH_FETCHING_FLAG,
        payload: {
          apiType: "getCurrentHandovers",
          value: false,
        },
      });
    }
  };

export const getOperationLeadersDetails = (supportArea) => async (dispatch) => {
  try {
    dispatch({
      type: SET_SH_FETCHING_FLAG,
      payload: {
        apiType: "getOperationLeadersDetails",
        value: true,
      },
    });

    const token = store.getState().at.APIMtoken;
    const response = await axios.post(
      `${BASE_URL_SH}/olinfodetails`,
      {
        Payload: {
          supportgroup: supportArea.replace("Support Team", "").trim(),
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = response.data?.response || {};

    dispatch({
      type: GET_SH_OPERATION_LEADS,
      payload: data,
    });
  } catch (error) {
    console.error(error);
  } finally {
    dispatch({
      type: SET_SH_FETCHING_FLAG,
      payload: {
        apiType: "getOperationLeadersDetails",
        value: false,
      },
    });
  }
};

export const addCommentOnIncident = async (payload) => {
  try {
    const token = store.getState().at.APIMtoken;
    const response = await axios.post(
      `${BASE_URL_SH}/addcomment`,
      {
        Payload: payload,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const newComments = response.data?.response?.[0];
    return newComments;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getIncidentDetails = (incidentId, incidentType) => async (dispatch) => {
  try {
    dispatch({
      type: SET_SH_FETCHING_FLAG,
      payload: {
        apiType: "getIncidentDetails",
        value: true,
      },
    });

    const token = store.getState().at.APIMtoken;
    const response = await axios.post(
      `${BASE_URL_SH}/incidentdetails`,
      {
        Payload: {
          incident_number: incidentId,
          shifttype: incidentType === "unattended" ? "Unattended" : "Handover",
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const details = response.data?.response;
    dispatch({
      type: GET_SH_INCIDENT_DETAILS,
      payload: details,
    });
  } catch (error) {
    console.error(error);
  } finally {
    dispatch({
      type: SET_SH_FETCHING_FLAG,
      payload: {
        apiType: "getIncidentDetails",
        value: false,
      },
    });
  }
};

export const setSelectedSupportArea = (supportArea) => (dispatch) => {
  dispatch({
    type: SET_SH_SELECTED_SUPPORT_AREA,
    payload: supportArea,
  });
};

export const getIncidentsCount = async (supportGroup, query) => {
  try {
    const token = store.getState().at.APIMtoken;
    const response = await axios.post(
      `${BASE_URL_SH}/incidentscount`,
      {
        Payload: {
          env: process.env.REACT_APP_ENV,
          supportgroup: supportGroup,
          query,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const count = response.data?.response;
    return count;
  } catch (error) {
    console.error(error);
    return {incidents_count:0,incident_details:[]};
  }
};

// export const getIncidentsCount =
//   (apiType, supportGroup, query) => async (dispatch) => {
//     try {
//       dispatch({
//         type: SET_SH_FETCHING_FLAG,
//         payload: {
//           apiType: apiType,
//           value: true,
//         },
//       });

//       const token = store.getState().at.APIMtoken;
//       const response = await axios.post(
//         `${BASE_URL_SH}/incidentscount`,
//         {
//           Payload: {
//             env: process.env.REACT_APP_ENV,
//             supportgroup: supportGroup,
//             query,
//           },
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );

//       const count = response.data?.response?.incidents_count || 0;
//       dispatch({
//         type: GET_SH_INCIDENT_COUNTS,
//         payload: { type: apiType, value: count },
//       });
//     } catch (error) {
//       console.error(error);
//     } finally {
//       dispatch({
//         type: SET_SH_FETCHING_FLAG,
//         payload: {
//           apiType: apiType,
//           value: false,
//         },
//       });
//     }
//   };

export const getOngoingPowerMaintenance = () => async (dispatch) => {
  try {
    dispatch({
      type: SET_SH_FETCHING_FLAG,
      payload: {
        apiType: "getOngoingPowerMaintenance",
        value: true,
      },
    });

    const token = store.getState().at.auth0token;
    const retailResponse = await axios.get(`${BASE_URL_PM}/pm/retail/api/v1/connector`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const supplyResponse = await axios.get(`${BASE_URL_PM}/pm/supply/api/v1/connector`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const retailData = retailResponse.data?.result || [];
    const supplyData = supplyResponse.data?.result || [];
    const data = [ ...retailData, ...supplyData];
    dispatch({
      type: GET_SH_ONGOING_PM,
      payload: data,
    });
  } catch (error) {
    console.error(error);
  } finally {
    dispatch({
      type: SET_SH_FETCHING_FLAG,
      payload: {
        apiType: "getOngoingPowerMaintenance",
        value: false,
      },
    });
  }
};

export const addIncidentToCSH = (inc) => async (dispatch) => {
  try {
    dispatch({
      type: SET_SH_FETCHING_FLAG,
      payload: {
        apiType: "addIncidentToCSH",
        value: true,
      },
    });
    const token = store.getState().at.APIMtoken;
    const auth0token = store.getState().at.auth0token;

    let status = inc.state.toLowerCase();
    status = ['new', 'in progress', 'on hold'].includes(status) ? 'inprogress' : status;
    let supportArea = inc.supportgroup.split("-")?.[0]?.trim();
    supportArea = supportAreaMapping[supportArea] || supportArea;
    const payload = {
      impact: inc.priority,
      message: inc.short_description,
      internal: {
        auditdoc_id: "",
        environment: "",
        modifiedOn: "",
        euuid: "",
        createdOn: "",
        parent_doc_id: "",
        action: "",
        reference_id: "",
        shifthandover: "true",
      },
      impactedMarkets: [],
      networkId: "",
      endDateTime: "",
      incidentNumber: inc.incident_number,
      extraInfo: "",
      status,
      modeOfCommunication: [],
      startDateTime: inc.start_date,
      typeOfEvent: "Critical Incident",
      greetingMessage: "",
      supportArea:[supportArea],
      lastupdateddate: inc.last_updated,
      assigned_group: inc.assigned_group,
      assigned_to: inc.assigned_to,
      location: inc.location,
      country: inc.country,
      comments: inc.comment,
    };

    const response = await axios.post(`${BASE_URL_CSH}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        Auth0_token: auth0token,
      },
    });
    if (response?.data?.status === "success") {
      await axios.post(
        `${BASE_URL_SH}/cshupdatestatus`,
        {
          Payload: {
            incident_number: inc.incident_number,
            CSHaddedstatus: "True",
            shifttype: inc.incidentsource !== "unattended" ? "Handover": "Unattended",
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(getIncidentDetails(inc.incident_number,  inc?.incidentsource));
    }
  } catch (error) {
    console.error(error);
  } finally {
    dispatch({
      type: SET_SH_FETCHING_FLAG,
      payload: {
        apiType: "addIncidentToCSH",
        value: false,
      },
    });
  }
};

export const getCommentHistory = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: SET_SH_FETCHING_FLAG,
      payload: {
        apiType: "getCommentHistory",
        value: true,
      },
    });

    const token = store.getState().at.APIMtoken;
    const response = await axios.post(
      `${BASE_URL_SH}/historyofcomments`,
      {
        Payload: payload,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const comments = response.data?.response?.commenthistory || [];
    dispatch({
      type: GET_SH_INCIDENT_COMMENTS_HISTORY,
      payload: comments,
    });
  } catch (error) {
    console.error(error);
  } finally {
    dispatch({
      type: SET_SH_FETCHING_FLAG,
      payload: {
        apiType: "getCommentHistory",
        value: false,
      },
    });
  }
};

export const getWorklogHistory = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: SET_SH_FETCHING_FLAG,
      payload: {
        apiType: "getWorklogHistory",
        value: true,
      },
    });

    const token = store.getState().at.APIMtoken;
    const response = await axios.post(
      `${BASE_URL_SH}/historyofworknotes`,
      {
        Payload: payload,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const worknotes = response.data?.response?.worknotes || [];
    dispatch({
      type: GET_SH_INCIDENT_WORKLOG_HISTORY,
      payload: worknotes,
    });
  } catch (error) {
    console.error(error);
  } finally {
    dispatch({
      type: SET_SH_FETCHING_FLAG,
      payload: {
        apiType: "getWorklogHistory",
        value: false,
      },
    });
  }
};

export const addWorklog = async (payload) => {
  try {
    const token = store.getState().at.APIMtoken;
    const response = await axios.put(
      `${BASE_URL_SH}/updateworknotes`,
      {
        Payload: payload,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response ? true : false;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getShiftHandoverAdminList = () => async (dispatch) => {
  try {
    dispatch({
      type: SET_SH_FETCHING_FLAG,
      payload: {
        apiType: "getShiftHandoverAdminList",
        value: true,
      },
    });
    const token = store.getState().at.APIMtoken;
    const response = await axios.get(`${BASE_URL_SH}/fetchaccess`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const adminList = response?.data?.response || [];
    dispatch({
      type: GET_SH_ADMIN_USERS_LIST,
      payload: adminList,
    });
  } catch (error) {
    console.error(error);
    return [];
  } finally {
    dispatch({
      type: SET_SH_FETCHING_FLAG,
      payload: {
        apiType: "getShiftHandoverAdminList",
        value: false,
      },
    });
  }
};

export const addShiftHandoverAdmin = (payload) => async (dispatch) => {
  try {
    const token = store.getState().at.APIMtoken;
    dispatch({
      type: SET_SH_FETCHING_FLAG,
      payload: {
        apiType: "addShiftHandoverAdmin",
        value: true,
      },
    });
    await axios.post(
      `${BASE_URL_SH}/grantaccess`,
      { Payload: payload },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({
      type: SET_SH_TOAST_VISIBLE,
      payload: {
        visible: true,
        message: "User(s) added successfully!",
      },
    });
    dispatch(getShiftHandoverAdminList());
  } catch (error) {
    console.error(`Error with add :: ${error}`);
    dispatch({
      type: SET_SH_TOAST_VISIBLE,
      payload: {
        visible: true,
        message: "Failed to add user(s), please try again!",
      },
    });
  } finally {
    dispatch({
      type: "addShiftHandoverAdmin",
      payload: {
        apiType: "addShiftHandoverAdmin",
        value: false,
      },
    });
  }
};

export const deleteAdminUsers = (payload) => async (dispatch) => {
  try {
    const token = store.getState().at.APIMtoken;

    dispatch({
      type: SET_SH_FETCHING_FLAG,
      payload: {
        apiType: "deleteAdminUsers",
        value: true,
      },
    });
    await axios.post(
      `${BASE_URL_SH}/revokeaccess`,
      { Payload: payload },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({
      type: SET_SH_TOAST_VISIBLE,
      payload: {
        visible: true,
        message: "User(s) deleted successfully!",
      },
    });
    dispatch(getShiftHandoverAdminList());
  } catch (error) {
    console.error(`Error with deleteAdminUsers :: ${error}`);
    dispatch({
      type: SET_SH_TOAST_VISIBLE,
      payload: {
        visible: true,
        message: "Failed to delete user(s), please try again!",
      },
    });
  } finally {
    dispatch({
      type: SET_SH_FETCHING_FLAG,
      payload: {
        apiType: "deleteAdminUsers",
        value: false,
      },
    });
  }
};

export const updateOperationLeadList = async (payload) => {
  try {
    const token = store.getState().at.APIMtoken;
    const res = await axios.post(
      `${BASE_URL_SH}/modifyshift`,
      { Payload: payload },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return { error: false, res: res.data };
  } catch (error) {
    console.error(`Error modifying ol list ${error}`);
    return { error: true };
  }
};

export const getShiftHandoverAgentRolesList = () => async (dispatch) => {
  try {
    dispatch({
      type: SET_SH_FETCHING_FLAG,
      payload: {
        apiType: "getShiftHandoverAgentRolesList",
        value: true,
      },
    });
    const token = store.getState().at.APIMtoken;
    const response = await axios.get(`${BASE_URL_SH}/fetchagentroles`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const adminList = response?.data?.response || [];
    dispatch({
      type: GET_SH_AGENT_ROLES_LIST,
      payload: adminList,
    });
  } catch (error) {
    console.error(error);
    return [];
  } finally {
    dispatch({
      type: SET_SH_FETCHING_FLAG,
      payload: {
        apiType: "getShiftHandoverAgentRolesList",
        value: false,
      },
    });
  }
};

export const addShiftHandoverAgentRole = (payload) => async (dispatch) => {
  try {
    const token = store.getState().at.APIMtoken;
    dispatch({
      type: SET_SH_FETCHING_FLAG,
      payload: {
        apiType: "addShiftHandoverAgentRole",
        value: true,
      },
    });
    await axios.post(
      `${BASE_URL_SH}/addagentrole`,
      { Payload: payload },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({
      type: SET_SH_TOAST_VISIBLE,
      payload: {
        visible: true,
        message: "Role(s) added successfully!",
      },
    });
    dispatch(getShiftHandoverAgentRolesList());
  } catch (error) {
    console.error(`Error with add :: ${error}`);
    dispatch({
      type: SET_SH_TOAST_VISIBLE,
      payload: {
        visible: true,
        message: "Failed to add role(s), please try again!",
      },
    });
  } finally {
    dispatch({
      type: SET_SH_FETCHING_FLAG,
      payload: {
        apiType: "addShiftHandoverAgentRole",
        value: false,
      },
    });
  }
};

export const deleteAgentRole = (payload) => async (dispatch) => {
  try {
    const token = store.getState().at.APIMtoken;

    dispatch({
      type: SET_SH_FETCHING_FLAG,
      payload: {
        apiType: "deleteAgentRole",
        value: true,
      },
    });
    await axios.post(
      `${BASE_URL_SH}/removeagentrole`,
      { Payload: payload },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({
      type: SET_SH_TOAST_VISIBLE,
      payload: {
        visible: true,
        message: "Job title(s) deleted successfully!",
      },
    });
    dispatch(getShiftHandoverAgentRolesList());
  } catch (error) {
    console.error(`Error with deleteAgentRole :: ${error}`);
    dispatch({
      type: SET_SH_TOAST_VISIBLE,
      payload: {
        visible: true,
        message: "Failed to delete agent job title(s), please try again!",
      },
    });
  } finally {
    dispatch({
      type: SET_SH_FETCHING_FLAG,
      payload: {
        apiType: "deleteAgentRole",
        value: false,
      },
    });
  }
};

export const getCDSUserData = (email) => async (dispatch) => {
  try {
    dispatch({
      type: SET_SH_FETCHING_FLAG,
      payload: {
        apiType: "getCDSUserData",
        value: true,
      },
    });
    const auth0token = store.getState().at.auth0token;
    const { data } = await axios.get(
      `https://iobserve-backend-soyaaeguoa-ez.a.run.app/aiops/cds/user?userId=${email}`,
      {
        headers: {
          Authorization: `Bearer ${auth0token}`,
        },
      }
    );
    dispatch({
      type: GET_SH_CDS_USER_DATA,
      payload: data,
    });
  } catch (error) {
    console.error(`Error fetching cds data :: ${error}`);
  }finally{
    dispatch({
      type: SET_SH_FETCHING_FLAG,
      payload: {
        apiType: "getCDSUserData",
        value: false,
      },
    });
  }
};

export const updateIncidentState = async (payload) => {
  try {
    const token = store.getState().at.APIMtoken;
    const res = await axios.post(
      `${BASE_URL_SH}/historyofworknotes`,
      { Payload: payload },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return { error: false, res: res.data };
  } catch (error) {
    console.error(`Error updating status ${error}`);
    return { error: true };
  }
};

export const postBanner = payload => async(dispatch) => {
  try {
    const token = store.getState().at.APIMtoken;
    await axios.post(
      `${BASE_URL_SH}/usernotification`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({
      type: SET_SH_TOAST_VISIBLE,
      payload: {
        visible: true,
        message: "Banner posted successfully!",
      },
    });
    dispatch(getBanner(payload?.supportGroup))
  } catch (error) {
    console.error(error);
    dispatch({
      type: SET_SH_TOAST_VISIBLE,
      payload: {
        visible: true,
        message: "Failed to post banner",
      },
    });
  } 
};

export const getBanner = (supportArea) => async(dispatch) => {
  try {
    dispatch({
      type: SET_SH_FETCHING_FLAG,
      payload: {
        apiType: "getBanner",
        value: true,
      },
    });
    const token = store.getState().at.APIMtoken;
    let supportgroup = supportArea.replace("Support Team", "").trim()
    const {data} = await axios.get(
      `${BASE_URL_SH}/getusernotification?supportgroup=${supportgroup}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({
      type: GET_SH_BANNER_MESSSAGE,
      payload: data,
    });
  } catch (error) {
    console.error(error);
    dispatch({
      type: SET_SH_FETCHING_FLAG,
      payload: {
        apiType: "getBanner",
        value: false,
      },
    });
  } 
};